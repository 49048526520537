<template>
  <div :class="formGroup ? 'form-group' : ''">
    <div class="row" v-if="label">
      <div class="col">
        <label
          v-bind:for="componentId"
          class="custom-label"
          v-bind:class="{
            'text-danger': error,
            'text-muted': labelDisabled,
          }"
          >{{ label }}</label
        >
        <i
          class="fas fa-question-circle ml-2"
          v-if="tooltip"
          v-tooltip="tooltipOptions"
        ></i>
      </div>
      <div class="col-auto" v-if="error">
        <div class="text-danger ml-auto">
          <small>
            <i v-if="required">{{ $t("requiredArea") }}</i>
          </small>
        </div>
      </div>
    </div>
    <div class="input-group" v-bind:class="{ 'input-group-sm': smallSize }">
      <!-- Prepend -->
      <template v-if="prepend">
        <div class="input-group-prepend">
          <span class="input-group-text" v-html="prepend"></span>
        </div>
      </template>
      <template v-if="this.$slots.prepend">
        <div class="input-group-prepend">
          <slot name="prepend"></slot>
        </div>
      </template>
      <!-- Prepend -->

      <input
        v-if="!currency"
        v-bind="$attrs"
        v-bind:autocomplete="autocomplete"
        v-bind:type="type"
        v-bind:name="componentId"
        v-bind:id="componentId"
        class="form-control"
        v-bind:class="{
          'border-danger': error,
        }"
        v-bind:placeholder="placeholder"
        v-bind:value="input"
        v-bind:readonly="readOnly"
        v-on:input="updateValue"
        v-on:focus="onFocus"
        v-on:keyup.enter="keyupEnter"
        v-on:blur="$emit('blur')"
        ref="input"
      />

      <input
        v-else
        v-bind="$attrs"
        v-bind:autocomplete="autocomplete"
        v-bind:type="type"
        v-bind:name="componentId"
        v-bind:id="componentId"
        class="form-control text-right"
        v-bind:class="{
          'border-danger': error,
        }"
        v-bind:placeholder="placeholder"
        v-bind:value="input"
        v-bind:readonly="readOnly"
        v-on:input="updateValue"
        v-on:keyup.enter="keyupEnter"
        v-currency="currencySetting"
        v-on:focus="$event.target.select()"
        v-on:blur="$emit('blur')"
        ref="currencyInput"
      />
      <!-- Append -->
      <template v-if="append">
        <div class="input-group-append">
          <span class="input-group-text">{{ append }}</span>
        </div>
      </template>
      <template v-if="this.$slots.append">
        <div class="input-group-append">
          <slot name="append"></slot>
        </div>
      </template>
      <!-- Append -->
    </div>
  </div>
</template>
<script>
import { CurrencyDirective, setValue, getValue } from "vue-currency-input";
export default {
  name: "WeInput",
  data() {
    return {};
  },
  directives: {
    currency: CurrencyDirective,
  },
  props: {
    readOnly: {
      default: false,
    },
    type: {
      default: "text",
    },
    label: {
      default: null,
    },
    formGroup: {
      default: true,
    },
    name: {
      default: null,
    },
    input: {
      default: null,
    },
    placeholder: {
      default: null,
    },
    error: {
      default: false,
    },
    required: {
      default: false,
    },
    prepend: {
      default: null,
    },
    append: {
      default: null,
    },
    currency: {
      default: false,
    },
    currencyCode: {
      default: "tr-TR",
    },
    tooltip: {
      default: "",
    },
    tooltipPosition: {
      default: "top",
    },
    filterNumber: {
      default: false,
    },
    decimal: {
      default: true,
    },
    maxValue: {
      default: null,
    },
    minValue: {
      default: null,
    },
    smallSize: {
      default: false,
    },
    labelDisabled: {
      default: false,
    },
    setOnMount: {
      default: true,
    },
    autocomplete: {
      default: "off",
    },
  },
  methods: {
    updateValue(e) {
      let value = e.target.value.trim();
      if (this.filterNumber && !this.currency) {
        const limitOptions = {
          max: this.maxValue,
          min: this.minValue,
        };

        value = helper.numberInput(value, this.decimal, limitOptions);
        this.$refs.input.value = value;
      }
      if (this.currency) {
        this.$refs.currencyInput.value = value;
      }
      this.$emit("input", value);
    },
    keyupEnter() {
      this.$emit("keyup-enter");
    },
    onFocus() {
      this.$emit("on-focus");
    },
    setInput() {
      let currencyInput = this.$refs.currencyInput;
      setValue(currencyInput, this.input);
    },
  },
  mounted() {
    if (this.currency && this.setOnMount) {
      this.setInput();
    }
  },
  watch: {
    input() {
      if (this.currency) {
        let currencyInput = getValue(this.$refs.currencyInput);
        this.$emit("unmask", currencyInput);
      }
    },
  },
  model: {
    prop: "input",
  },
  computed: {
    componentId() {
      return (
        (this.name || this.label || "input") +
        "-" +
        helper.getRandomInteger(1, 999999)
      );
    },
    currencySetting() {
      let data = {
        locale: this.currencyCode,
        currency: null,
        precision: 2,
        allowNegative: false,
        autoDecimalMode: false,
      };
      return data;
    },
    tooltipOptions() {
      const data = {
        content: this.tooltip,
        placement: this.tooltipPosition,
      };

      return data;
    },
  },
};
</script>
