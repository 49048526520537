var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.formGroup ? 'form-group' : ''},[(_vm.label)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('label',{staticClass:"custom-label",class:{
          'text-danger': _vm.error,
          'text-muted': _vm.labelDisabled,
        },attrs:{"for":_vm.componentId}},[_vm._v(_vm._s(_vm.label))]),(_vm.tooltip)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipOptions),expression:"tooltipOptions"}],staticClass:"fas fa-question-circle ml-2"}):_vm._e()]),(_vm.error)?_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"text-danger ml-auto"},[_c('small',[(_vm.required)?_c('i',[_vm._v(_vm._s(_vm.$t("requiredArea")))]):_vm._e()])])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"input-group",class:{ 'input-group-sm': _vm.smallSize }},[(_vm.prepend)?[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",domProps:{"innerHTML":_vm._s(_vm.prepend)}})])]:_vm._e(),(this.$slots.prepend)?[_c('div',{staticClass:"input-group-prepend"},[_vm._t("prepend")],2)]:_vm._e(),(!_vm.currency)?_c('input',_vm._b({ref:"input",staticClass:"form-control",class:{
        'border-danger': _vm.error,
      },attrs:{"autocomplete":_vm.autocomplete,"type":_vm.type,"name":_vm.componentId,"id":_vm.componentId,"placeholder":_vm.placeholder,"readonly":_vm.readOnly},domProps:{"value":_vm.input},on:{"input":_vm.updateValue,"focus":_vm.onFocus,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.keyupEnter.apply(null, arguments)},"blur":function($event){return _vm.$emit('blur')}}},'input',_vm.$attrs,false)):_c('input',_vm._b({directives:[{name:"currency",rawName:"v-currency",value:(_vm.currencySetting),expression:"currencySetting"}],ref:"currencyInput",staticClass:"form-control text-right",class:{
        'border-danger': _vm.error,
      },attrs:{"autocomplete":_vm.autocomplete,"type":_vm.type,"name":_vm.componentId,"id":_vm.componentId,"placeholder":_vm.placeholder,"readonly":_vm.readOnly},domProps:{"value":_vm.input},on:{"input":_vm.updateValue,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.keyupEnter.apply(null, arguments)},"focus":function($event){return $event.target.select()},"blur":function($event){return _vm.$emit('blur')}}},'input',_vm.$attrs,false)),(_vm.append)?[_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.append))])])]:_vm._e(),(this.$slots.append)?[_c('div',{staticClass:"input-group-append"},[_vm._t("append")],2)]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }